import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      toast.error('Proszę wypełnić CAPTCHA');
      return;
    }

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      'g-recaptcha-response': captchaToken,
    };

    emailjs.send('service_0pdvdpq', 'template_k5bosnb', templateParams, 'HzpKddMFIOub0Rzqe')
      .then((result) => {
        toast.success('Wiadomość wysłana pomyślnie!');
      }, (error) => {
        toast.error('Błąd podczas wysyłania wiadomości, spróbuj ponownie.');
      });

    setFormData({
      name: '',
      email: '',
      message: ''
    });
    setCaptchaToken(null);
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Napisz do nas!</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Masz jakieś pytania? Sugestie? Śmiało, napisz do nas! ;)
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <form onSubmit={sendEmail} className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">Imię</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                  required 
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                  required 
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="message" className="leading-7 text-sm text-gray-600">Wiadomość</label>
                <textarea 
                  id="message" 
                  name="message" 
                  value={formData.message} 
                  onChange={handleChange} 
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" 
                  required 
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <ReCAPTCHA
                sitekey="6LfXymYqAAAAAHwgUFnxG2xEgBAAIkZBbR9gYYzK"
                onChange={handleCaptchaChange} 
              />
            </div>
            <div className="p-2 w-full">
              <button className="inline-flex items-center text-white buttonnav border-0 py-3 px-6 focus:outline-none hover:bg-gray-200 rounded-full text-base mt-4 md:mt-0">
                Wyślij wiadomość
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <a href="mailto:wojciechbecella@gmail.com" className="text-indigo-500">wojciechbecella@gmail.com</a>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default Contact;
