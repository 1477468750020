import React from 'react';
import imgreact from '../assets/react.webp';
import imgmongodb from '../assets/mongodb.webp';
import imgnextjs from '../assets/nextjs.webp';
import imgnodejs from '../assets/nodejs.webp';
import imgpostgresql from '../assets/postgresql.webp';
import imgwordpress from '../assets/wordpress.png';
import imgtailwind from '../assets/tailwind.png';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import { Tooltip } from 'react-tooltip'; // Zaktualizowane importowanie Tooltip


const Slider = ({ backgroundColor, textColor }) => {
const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <div className={`${backgroundColor}`}>


            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                <h1 className={`uppercase sm:text-3xl text-2xl pt-5 font-medium title-font mb-2 ${textColor}`}>

                    Pracujemy z nowoczesnymi technologiami
                </h1>
            </div>
            <div className='flex justify-center items-center'>
                <div className="w-full max-w-xl">
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={1500}
                        infinite={true}
                        arrows={false}
                    >
                        {[
                            { src: imgreact, alt: "React" },
                            { src: imgtailwind, alt: "Tailwind" },
                            { src: imgmongodb, alt: "MongoDB" },
                            { src: imgwordpress, alt: "WordPress" },
                            { src: imgnodejs, alt: "Node.js" },
                            { src: imgpostgresql, alt: "PostgreSQL" }
                        ].map((img, index) => (
                            <div key={index} className="img-gray flex justify-center relative">
                                <img src={img.src} alt={img.alt} data-tooltip-id={`tooltip-${index}`} className="max-w-full img-gray h-auto mb-5" />
                                <Tooltip 
                                    id={`tooltip-${index}`} 
                                    place="top" 
                                    content={img.alt} 
                                    className="bg-white text-gray-900 border border-gray-200 rounded-lg shadow-lg" 
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default Slider;
