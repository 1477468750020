import React from "react";
import gbmedia from '../assets/gbmedia.png';
import atlbox from '../assets/atlbox.png';
import vibery from '../assets/vibery.png';
import wolfish from '../assets/wolfish.png'


const Realizacje = () => {
  // Tablica obiektów z danymi o realizacjach

  const projects = [
    {
      id: 1,
      title: "GBMedia", 
      description:
        "Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra irony.",
      imgSrc: gbmedia,
      technology: ["React", "Tailwind CSS", "Framer Motion"],
      link: "https://gbmedia.pl" // Link do projektu
    },
    {
      id: 2,
      title: "Atlbox",
      description:
        "Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra irony.",
      imgSrc: atlbox,
      technology: ["Vue.js", "Bootstrap"],
      link: "https://atlbox.no" // Link do projektu
    },
    {
      id: 3,
      title: "Vibery",
      description:
        "Opis nowego projektu. Coś ciekawego o tym projekcie.",
      imgSrc: vibery,
      technology: ["Angular", "Material UI"],
      link: "https://vibery.pl" // Link do projektu
    },
    {
      id: 4,
      title: "Wolfish",
      description:
        "Opis nowego projektu. Coś ciekawego o tym projekcie.",
      imgSrc: wolfish,
      technology: ["Next.js", "Tailwind CSS", "WordPress"],
      link: "https://wolfish-sklep.pl" // Link do projektu
    },
  ];


  return (
    <section className="realizacje text-white body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -mx-4 -mb-10 text-center">
          {projects.map((project) => (
            <div key={project.id} className="sm:w-1/2 mb-10 px-4 xl:w-1/2">
              {/* Kontener dla obrazka */}
              <div className="relative rounded-lg h-max overflow-hidden group">
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  <img
                    alt="content"
                    className="cursor-pointer object-cover object-center h-full w-full transition-transform duration-300 ease-in-out hover:scale-105"
                    src={project.imgSrc}
                  />
                </a>
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                <button className="py-4 px-4 sm:py-1 sm:px-2 absolute bottom-6 left-1/2 transform -translate-x-1/2 text-white bg-black border border-white lg:py-3 lg:px-10 focus:outline-none rounded-full transition-colors duration-300 ease-in-out group-hover:bg-white group-hover:text-black">
  Zobacz realizację
</button>

                </a>
              </div>
              <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3">
                {project.title}
              </h2>
              <p className="leading-relaxed text-base text-left">{project.description}</p>
              <div className="techno1">
                {/* Wyświetl technologie jako listę */}
                {project.technology.map((tech, index) => (
                  <span key={index} className="techno inline-block bg-gray-200 text-gray-800 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Realizacje;
