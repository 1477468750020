import React from 'react';
import Steps from './Steps';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Slider from './Slider';
import moon from '../assets/moon.webp'

const Home = () => {
  return (
    <div>
      <div className="hero relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-24 lg:py-24">
          <div className="text-center">
            <h1 className="wb text-balance text-4xl font-bold tracking-tight  text-white sm:text-6xl">
              Zrealizuj pomysł z 
              <br />
              <span>WB</span>
            </h1>
            <p className="mt-6 text-lg leading-8 text-white">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat aliqua.
            </p>
          </div>
          <div className=''>
          <img src={moon} className='moon' alt="Ksiezyc"></img>
        </div>
        </div>

      </div>

      <section className="oferta text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              Nasza oferta
            </h1>
            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
              Zapoznaj się z naszą ofertą - usługi programistyczne na najwyższym poziomie.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            {[...Array(6)].map((_, index) => (
              <AnimatedCard key={index} index={index} />
            ))}
          </div>
        </div>
      </section>
      <Steps />
      <Slider textColor="text-black"/>
    </div>
  );
};

const AnimatedCard = ({ index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="xl:w-1/3 md:w-1/2 p-4">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-white border border-gray-200 p-6 rounded-lg">
          <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-6 h-6"
              viewBox="0 0 24 24"
            >
              {index === 0 && <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>}
              {index === 1 && (
                <>
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </>
              )}
              {index === 2 && <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>}
              {index === 3 && <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>}
              {index === 4 && <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>}
              {index === 5 && <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>}
            </svg>
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            {index === 0 && "Strony internetowe"}
            {index === 1 && "Sklepy internetowe"}
            {index === 2 && "Neptune"}
            {index === 3 && "Melanchole"}
            {index === 4 && "Bunker"}
            {index === 5 && "Ramona Falls"}
          </h2>
          <p className="leading-relaxed text-base">
          {index === 0 && "Opis strony internetowej Opis strony internetowej"}
            {index === 1 && "Opis strony internetowej Opis strony internetowej"}
            {index === 2 && "Opis strony internetowej Opis strony internetowej"}
            {index === 3 && "Melanchole"}
            {index === 4 && "Bunker"}
            {index === 5 && "Ramona Falls"}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
