import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'

export const Header = () => {
  return (
<header class="header body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a class="wb flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <Link to="/home">
<img src={logo} alt="logo WB" className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24" // Zmienne rozmiary na różnych ekranach
></img>
      </Link>
    </a>
    <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">

<Link className='menu mr-5' to="/home">
Strona główna
</Link>

<Link className='menu mr-5' to="/about">
Realizacje
</Link>

<Link className='menu mr-5' to="/contact">
Kontakt
</Link>

    </nav>
    <Link to="/contact">
    <button class="inline-flex items-center text-white buttonnav border-0 py-3 px-6 focus:outline-none hover:bg-gray-200 rounded-full text-base mt-4 md:mt-0">

Kontakt
      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
        <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
    </button>
    </Link>

  </div>
</header>
  )
}
