import React, { useEffect } from 'react';
import { Header } from './components/Header';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import About from './components/About';
import Footer from './components/Footer';
import Home from './components/Home';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import './nprogress.css';
import Contact from './components/Contact';

NProgress.configure({ showSpinner: true });

const RouteChangeHandler = () => {
  const location = useLocation();

  useEffect(() => {
    NProgress.start();
    NProgress.done();

    return () => {
      // Cleanup logic if needed
    };
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <RouteChangeHandler />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
