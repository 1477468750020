import React from 'react'
import Realizacje from './Realizacje'
import Slider from './Slider'
import Counting from './Counting'

const About = () => {
  return (
    <div>
      <Counting />
      <Realizacje />
      <Slider backgroundColor="realizacje" textColor="text-white"/>
    </div>
  )
}

export default About