import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Steps = () => {
  const {ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,

  });

      return (
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto flex flex-wrap">
            <div className="flex flex-wrap w-full">
              <div className="lg:w-2/5 md:w-auto md:pr-10 md:py-6">
                {steps.map((step, index) => (
                  <div className="flex relative pb-12" key={index}>
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <motion.div
                     ref={ref}
                     initial={{ opacity: 0, y: 20 }} // Początkowy stan
                     animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} // Animacja przy przewijaniu
                     transition={{ duration: 0.5 }} // Czas trwania animacji
                    >
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                      {step.icon}
                    </div>
                    </motion.div>
                    <div className="flex-grow pl-4">
                    <motion.div
 ref={ref}
 initial={{ opacity: 0, y: 20 }} // Początkowy stan
 animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} // Animacja przy przewijaniu
 transition={{ duration: 1 }} // Czas trwania animacji
                    >
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">{step.title}</h2>
                     </motion.div>
                      <p className="leading-relaxed">{step.description}</p>
                    </div>
                  </div>
                ))}
                <div className="flex relative">
                <motion.div
 ref={ref}
 initial={{ opacity: 0, y: 20 }} // Początkowy stan
 animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} // Animacja przy przewijaniu
 transition={{ duration: 0.5 }} // Czas trwania animacji
                    >
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    {finishIcon}
                  </div>
                  </motion.div>
                  <div className="flex-grow pl-4">
                  <motion.div
 ref={ref}
 initial={{ opacity: 0, y: 20 }} // Początkowy stan
 animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }} // Animacja przy przewijaniu
 transition={{ duration: 1 }} // Czas trwania animacji
                    >
                    <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">FINISH</h2>
                    </motion.div>
                    <p className="leading-relaxed">Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.</p>
                  </div>
                </div>
              </div>

              <img className="lg:w-3/5 md:w-auto object-cover object-center rounded-lg md:mt-0 mt-12" src="https://sodreams.pl/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ftelefony.de051605.png&w=1200&q=75" alt="step" />

            </div>
          </div>
        </section>
      );
    };
    
    const steps = [
      {
        title: 'STEP 1',
        description: 'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        icon: (
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        ),
      },
      {
        title: 'STEP 2',
        description: 'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        icon: (
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        ),
      },
      {
        title: 'STEP 3',
        description: 'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        icon: (
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
          </svg>
        ),
      },
      {
        title: 'STEP 4',
        description: 'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        icon: (
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        ),
      },
    ];
    
    const finishIcon = (
      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
        <path d="M22 4L12 14.01l-3-3"></path>
      </svg>
    );
    
    export default Steps;
    
